.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  width: 100%;
  text-align: left;
  background: white;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  padding: 0.4rem 0.4rem;

  &.isSelected {
    background-color: var(--blue4);
  }

  img {
    width: 1em;
    height: 1em;
  }
}
