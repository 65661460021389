@import "@radix-ui/colors/slate.css";
@import "@radix-ui/colors/blue.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/red.css";
@import "@radix-ui/colors/purple.css";
@import "@radix-ui/colors/yellow.css";
@import "@radix-ui/colors/whiteA.css";
@import "@radix-ui/colors/blackA.css";
@import "@radix-ui/colors/slateA.css";

:root {
  --accentBase: #0062ff; /* Main color */
  --accentBgSubtle: #f0f7ff; /* Lightest shade */
  --accentBg: #0062ff; /* Lighter shade */
  --accentBgHover: #c7ddff; /* Light shade */
  --accentBgActive: #a8cbff; /* Noticeably blue, but still light */
  --accentLine: #0062ff; /* Medium-light blue */
  --accentBorder: #0062ff; /* A lighter version of the main color */
  --accentBorderHover: #0062ff; /* A slightly darker shade of the main color */
  --accentSolid: #0062ff; /* Darker shade */
  --accentSolidHover: #0062ff; /* A deep blue, much darker than the main color */
  --accentText: #0062ff; /* The darkest shade, providing strong contrast */
  --accentTextContrast: #0062ff; /* Main color for contrast text */

  --successBase: var(--green1);
  --successBgSubtle: var(--green2);
  --successBg: var(--green3);
  --successBgHover: var(--green4);
  --successBgActive: var(--green5);
  --successLine: var(--green6);
  --successBorder: var(--green7);
  --successBorderHover: var(--green8);
  --successSolid: var(--green9);
  --successSolidHover: var(--green10);
  --successText: var(--green11);
  --successTextContrast: var(--green12);

  --warningBase: var(--yellow1);
  --warningBgSubtle: var(--yellow2);
  --warningBg: var(--yellow3);
  --warningBgHover: var(--yellow4);
  --warningBgActive: var(--yellow5);
  --warningLine: var(--yellow6);
  --warningBorder: var(--yellow7);
  --warningBorderHover: var(--yellow8);
  --warningSolid: var(--yellow9);
  --warningSolidHover: var(--yellow10);
  --warningText: var(--yellow11);
  --warningTextContrast: var(--yellow12);

  --dangerBase: var(--red1);
  --dangerBgSubtle: var(--red2);
  --dangerBg: var(--red3);
  --dangerBgHover: var(--red4);
  --dangerBgActive: var(--red5);
  --dangerLine: var(--red6);
  --dangerBorder: var(--red7);
  --dangerBorderHover: var(--red8);
  --dangerSolid: var(--red9);
  --dangerSolidHover: var(--red10);
  --dangerText: var(--red11);
  --dangerTextContrast: var(--red12);

  --purpleBase: var(--purple1);
  --purpleBgSubtle: var(--purple2);
  --purpleBg: var(--purple3);
  --purpleBgHover: var(--purple4);
  --purpleBgActive: var(--purple5);
  --purpleLine: var(--purple6);
  --purpleBorder: var(--purple7);
  --purpleBorderHover: var(--purple8);
  --purpleSolid: var(--purple9);
  --purpleSolidHover: var(--purple10);
  --purpleText: var(--purple11);
  --purpleTextContrast: var(--purple12);

  --grayBase: var(--slate1);
  --grayBgSubtle: var(--slate2);
  --grayBg: var(--slate3);
  --grayBgHover: var(--slate4);
  --grayBgActive: var(--slate5);
  --grayLine: var(--slate6);
  --grayBorder: var(--slate7);
  --grayBorderHover: var(--slate8);
  --grayFocusRing: var(--slate8);
  --graySolid: var(--slate9);
  --grayPlaceholderText: var(--slate9);
  --graySolidHover: var(--slate10);
  --grayText: var(--slate11);
  --grayTextContrast: var(--slate12);

  --panel: white;
  --panelContrast: var(--blackA11);
  --shadow: var(--slateA3);
  --overlay: var(--blackA8);

  --authPageBg: var(--grayBgSubtle);
  --authFormBg: var(--whiteA12);
  --authFormBorder: var(--grayBorder);

  --tabInactiveBorder: var(--grayLine);
  --tabInactiveText: var(--grayPlaceholderText);
  --tabActiveBorder: var(--accentBorder);
  --tabActiveText: var(--accentText);

  --inputBg: var(--whiteA12);
  --inputText: var(--grayTextContrast);
  --inputPlaceholderText: var(--grayPlaceholderText);

  --buttonTextColor: var(--grayText);
  --buttonTextColorHover: var(--grayText);

  /* Planner */
  --editorAttributionHeaderColor: var(--grayText);
  --editorAttributionHeaderActiveColor: var(--grayTextContrast);
  --editorAttributionHeaderCaret: var(--accentSolid);
  --editorHeaderGradient: linear-gradient(
    rgba(238, 238, 238, 0.5) 0%,
    rgba(238, 238, 238, 0.447) 10.67%,
    rgba(238, 238, 238, 0.396) 19.64%,
    rgba(238, 238, 238, 0.353) 27.12%,
    rgba(238, 238, 238, 0.31) 33.33%,
    rgba(238, 238, 238, 0.275) 38.51%,
    rgba(238, 238, 238, 0.243) 42.88%,
    rgba(238, 238, 238, 0.21) 46.65%,
    rgba(238, 238, 238, 0.184) 50.06%,
    rgba(238, 238, 238, 0.157) 53.33%,
    rgba(238, 238, 238, 0.133) 56.68%,
    rgba(238, 238, 238, 0.106) 60.33%,
    rgba(238, 238, 238, 0.082) 64.52%,
    rgba(238, 238, 238, 0.055) 69.46%,
    rgba(238, 238, 238, 0.027) 75.38%,
    rgba(238, 238, 238, 0) 82.5%
  );

  --appBg: white;
  --plannerBg: white;
  --editorTitleColor: var(--blackA12);
  --editorTitleBorderColor: var(--grayLine);

  /* FROM LIVEBLOCKS EDITOR */

  --color-black: 0 0 0;
  --color-white: 255 255 255;
  --color-gray-50: 250 250 250;
  --color-gray-100: 244 244 245;
  --color-gray-150: 236 236 238;
  --color-gray-200: 228 228 231;
  --color-gray-250: 220 220 224;
  --color-gray-300: 212 212 216;
  --color-gray-350: 187 187 193;
  --color-gray-400: 161 161 170;
  --color-gray-450: 137 137 146;
  --color-gray-500: 113 113 122;
  --color-gray-550: 98 98 107;
  --color-gray-600: 82 82 91;
  --color-gray-650: 73 73 81;
  --color-gray-700: 63 63 70;
  --color-gray-750: 51 51 56;
  --color-gray-800: 39 39 42;
  --color-gray-850: 32 32 35;
  --color-gray-900: 24 24 27;
  --color-gray-950: 18 18 21;
  --color-overlay: rgb(var(--color-black) / 60%);
  --space-0: 0;
  --space-1: 0.125rem;
  --space-2: 0.25rem;
  --space-3: 0.375rem;
  --space-4: 0.5rem;
  --space-5: 0.625rem;
  --space-6: 0.75rem;
  --space-7: 0.875rem;
  --space-8: 1rem;
  --space-9: 1.25rem;
  --space-10: 1.5rem;
  --space-11: 1.75rem;
  --space-12: 2rem;
  --space-13: 2.25rem;
  --space-14: 2.5rem;
  --space-15: 3rem;
  --space-16: 3.5rem;
  --space-17: 4rem;
  --space-18: 4.5rem;
  --space-19: 5rem;
  --space-20: 6rem;
  --space-21: 7rem;
  --space-22: 8rem;
  --space-23: 9rem;
  --space-24: 10rem;
  --size-2xs: 0.625rem;
  --size-xs: 0.75rem;
  --size-sm: 0.875rem;
  --size: 1rem;
  --size-lg: 1.125rem;
  --size-xl: 1.25rem;
  --size-2xl: 1.5rem;
  --size-3xl: 2rem;
  --size-4xl: 3rem;
  --radius-xs: 0.3rem;
  --radius-sm: 0.4rem;
  --radius: 0.7rem;
  --radius-lg: 0.8rem;
  --shadow-xs: 0 1px 4px rgb(var(--color-black) / 5%);
  --shadow-sm: 0 2px 8px rgb(var(--color-black) / 5%);
  --shadow: 0 3px 10px rgb(var(--color-black) / 5%);
  --shadow-lg: 0 4px 20px rgb(var(--color-black) / 5%);
  --shadow-xl: 0 5px 30px rgb(var(--color-black) / 5%);
  --backdrop-surface: saturate(2) blur(16px);
  --transition: 0.15s ease-in-out;
  --transition-linear: 0.15s linear;
  --header-height: 60px;
  --z-above: 100;
  --z-overlay: 200;
  --z-badge: 1000;
  --opacity-hover: 0.8;
  --opacity-disabled: 0.5;

  accent-color: var(--color-accent);

  --color-red: 239 67 67;
  --color-green: 132 204 22;
  --color-accent: rgb(var(--color-gray-900));
  --color-surface: rgb(var(--color-gray-100));
  --color-surface-hover: rgb(var(--color-gray-150));
  --color-surface-elevated: rgb(var(--color-white));
  --color-border: rgb(var(--color-gray-150));
  --color-border-contrasted: rgb(var(--color-gray-250));
  --color-border-transparent: rgb(var(--color-gray-900) / 10%);
  --color-skeleton: rgb(var(--color-gray-150));
  --color-skeleton-shine: rgb(var(--color-gray-50));
  --color-tooltip: rgb(var(--color-gray-950));
  --color-tooltip-text: rgb(var(--color-white));
  --color-tooltip-border: rgb(var(--color-gray-750));
  --color-text: rgb(var(--color-gray-900));
  --color-text-light: rgb(var(--color-gray-600));
  --color-text-lighter: rgb(var(--color-gray-500));
  --color-text-lightest: rgb(var(--color-gray-400));
}
