.container {
  display: flex;
  flex-direction: column;
  background: var(--color-surface);
  height: 100%;
  /* position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
}

.editorPanel {
  flex: 1 1;
  min-height: calc(100vh - 103px);
}

.editor {
  border-radius: inherit;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: var(--space-20);
}

.editor:focus {
  outline: none;
}

.editorContainer {
  position: relative;
  min-height: 100%;
  width: 100%;
  max-width: 900px;
  margin: var(--space-8) auto;
  border: 1px solid var(--color-border);
  background: var(--color-surface-elevated);
}

.placeholder {
  pointer-events: none;
  position: absolute;
  inset: 0;
  opacity: 0.3;
  padding: 0 1em;
}

.bubbleMenuWrapper {
  display: flex;
  background: var(--color-surface-elevated);
  gap: var(--space-2);
  padding: var(--space-3);
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  border: 1px solid var(--color-border);
  border-radius: var(--radius-sm);
}

.typing-indicator {
  display: flex;
  align-items: center;
}

.dots {
  display: flex;
  align-items: center;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: var(--blue11);
  margin: 0 2px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.dot1 {
  animation-name: dot1Animation;
}

.dot2 {
  animation-name: dot2Animation;
}

.dot3 {
  animation-name: dot3Animation;
}

@keyframes dot1Animation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

@keyframes dot2Animation {
  0%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-4px);
  }
  75% {
    transform: translateY(4px);
  }
}

@keyframes dot3Animation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
}
